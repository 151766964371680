/// @group MediaText
.wp-block-media-text {
    &.is-style-card {
        border-radius: $border-radius;
        overflow: hidden;
        box-shadow: $box-shadow;
        padding: 0 !important;

        .wp-block-media-text__media {
            align-self: stretch;
            height: 100%;
            border-radius: $border-radius;
            overflow: hidden;

            img {
                min-height: 100%;
                min-width: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
        .wp-block-media-text__content {
            padding: 4.5rem 3rem;
        }
    }
}