/// @group Blocks
#entry-content {
    h1,h2,h3,h4,h5,h6,
    p,
    ul,ol,dl {
        margin-bottom: 1.5rem;
    }
    ul, ol, dl {
        &.is-style-references {
            li {
                margin-bottom: 1.5rem;
                padding-left: 1rem;
                padding-right: 1rem;
                &::marker {
                    font-weight: bold;
                }
            }
            @include media-breakpoint-up(lg) {
                column-width: 33vw;
            }
        }
    }

    hr {
        margin: 3rem auto 2rem;
        &.wp-block-separator {
            border-bottom: none;
        }
    }

    sup {
        &,
        a {
            color: $turqois-ada;
            font-weight: bold;
        }

    }
}

/// @group Alignment
.alignwide {
    @extend .container;
    .alignwide {
        padding-left: 0;
        padding-right: 0;
    }
}

.alignfull {
    @extend .container-fluid;
    .alignfull {
        padding-left: 0;
        padding-right: 0;
    }
}

/// @group Overlap
.is-style-overlap {
    margin-bottom: -200px;
    &--behind {
        padding-bottom: 200px;
    }
    &:not(.is-style-overlap--behind) {
        & + * {
            padding-top: 240px !important;
        }
    }
}

/// @group  Columns
.wp-block-columns {
    gap: 2rem !important;

    .wp-block-column {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;

        padding: 0 1rem;
        &.has-background {
            padding: 2rem;
            border-radius: $border-radius;
        }
    }
}

/// @group Cover
.wp-block-cover {
    &.is-style-default {
        padding: 2rem;
    }
    .is-style-hero-impact & {
        align-items: flex-end;
        .wp-block-cover__inner-container {
            height: 100%;
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
}

/// @group Group
:where(.wp-block-group.has-background) {
    padding: 0;
}
.wp-block-group {
    > .wp-block-image {
        flex-shrink: 0;
        flex-grow: 0;
    }
}

/// @group MediaText
.wp-block-media-text {
    &.is-style-add-padding {
        padding-top: 4rem;
        padding-bottom: 4rem;
        @include media-breakpoint-down(lg) {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }

        &.alignwide {
            .wp-block-media-text__media img {
                border-radius: $border-radius;
            }
        }
    }
    & &__content {
        padding: 0 1rem;
        @include media-breakpoint-down(xl) {
            padding: 2rem 1rem;
        }
    }
    .is-style-hero-teachers & {
        .wp-block-media-text__media {
            margin-left: 3rem;
        }
    }
    &:not(.has-media-on-the-right) &__media {
        margin-right: 3rem;
    }
    &.has-media-on-the-right &__media {
        margin-left: 3rem;
    }

    .wp-block-columns {
        @media (max-width: 781px) {
            gap: 0 !important;
            .wp-block-column {
                margin-top: 0;
                margin-bottom: 0;

                ul, ol {
                    margin-bottom: 0;
                }
            }
        }
    }

}

/// @group Hero
[class*="is-style-hero"] {
    padding: 0;
    .wp-block-columns {
        margin-bottom: 0;
    }
    .wp-block-buttons {
        margin-top: 1.5rem;
    }
    &.is-style-hero-home {
        $url: asset('images/CEE-ripple-corner-2.png');
        background-image: url($url);
        background-size: 655px;
        background-repeat: no-repeat;
        background-position: calc(100% + 100px) calc(100% + 300px);

        .wp-block-column {
            margin-bottom: 0;
            margin-top: 0;
            &:first-child {
                @media (min-width: 768px) and (max-width: 992px) {
                    padding-top: 2rem;
                    padding-bottom: 2rem;
                }
            }
        }
    }

    &.is-style-hero-teachers {
        // $url: asset('images/teachers-hero-graphic@2x.png');
        // background-image: url($url);
        // background-repeat: no-repeat;
        // background-size: contain;
        // background-position: bottom right;

        padding-top: 5rem;
        padding-bottom: 5rem;

        @include media-breakpoint-up(lg) {
            position: relative;
            .wp-block-media-text__media {
                img {
                    position: relative;
                }
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 68%;
                    height: 100%;

                    $url: asset('images/teachers-hero-graphic@2x.png');
                    background-image: url($url);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: bottom right;
                }
            }
        }
    }

    a {
        text-decoration: none;
        font-weight: bold;
        @include on-action {
            text-decoration: underline;
        }
    }
}

/// @group Tease
.post-tease {
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    h2, h3, h4, h5, h6 {
        a {
            color: inherit;
            text-decoration: none;
        }
    }
    .image-placeholder {
        &--3 {
            position: relative;
            &:after {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                z-index: 2;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-image: url(/wp-content/themes/usdigital-child/assets/dist/images/blog-placeholder-thumbnail-1.png);
                background-size: cover;
                background-repeat: no-repeat;
                border-radius: $border-radius;
                overflow: hidden;
            }
            .has-background & {
                &:after {
                    display: none;
                }
            }
        }
    }
}

/// @group Card
.wp-block-usdigital-custom-blocks-card {
    .has-full-height-cards & {
        @include media-breakpoint-up(sm) {
            height: 100%;
        }
    }
    .wp-block-column & {
        // height: 100%;
        display: flex;
        flex-direction: column;
        .wp-block-buttons:last-child {
            margin-top: auto;
        }
    }
    &.is-style-gray {
        background-color: $gray-light !important;
    }
    &.is-style-image {
        padding: 1.25rem 1.25rem 1.5rem !important;
        border-top: none !important;
        border-radius: $border-radius;
        background-color: $white;
        figure {
            width: calc(100% + 2.5rem);
            margin-left: -1.25rem;
            margin-top: -1.25rem;
            > div {
                width: 100% !important;
            }
            img {
                width: 100% !important;
                height: auto;
                margin-bottom: 1.5rem;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        padding: 1rem 2rem !important;
    }
}

/// @group Nav
.wp-block-usdigital-custom-blocks-tabs {
    .nav.nav-pills {
        .nav-link {
            text-align: left;
            font-size: 24px;
            font-weight: bold;
            color: $body-color;
            margin-bottom: 1.5rem;
            &:hover,
            &.active {
                text-decoration: underline;
                color: $forest;
                background-color: transparent;
            }
        }
    }
}

/// @group Blockquote
.wp-block-quote {
    &.is-style-plain {
        @include media-breakpoint-down(sm) {
            padding-left: 0;
        }
    }

    @include media-breakpoint-down(sm) {
        & ~ .wp-block-group figure.is-style-rounded {
            width: 70px;
            margin-right: 12px;
        }
    }
}
