/// @group Breadcrumb
.breadcrumb {
    .breadcrumb-item:not(.active) {
        a {
            color: $body-color;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}