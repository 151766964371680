/// @group Image
.wp-block-image {
    &.is-style-rounded-corners,
    &.is-style-drop-shadow {
        img {
            border-radius: $border-radius !important;
        }
    }
    &.is-style-drop-shadow,
    &.is-style-rounded {
        img {
            box-shadow: $box-shadow !important;
        }
    }
}

// Image line through on homepage
.is-style-image-line-through {
    position: relative;
    &:before {
       @include media-breakpoint-up(md) {
            content: '';
        }
        position: absolute;
        width: 100vw;
        .is-sidebar-opened & {
            width: 100%
        }
        height: 7px;
        background-color: $navy;
        display: block;
        top: calc(103px + 1.5rem);
        left: 0;
        @each $bp, $px in $container-max-widths {
            @include media-breakpoint-up($bp) {
                left: calc((100vw - #{$px}) * .5 * -1);
                .is-sidebar-opened & {
                    left: 0;
                }
            }
        }
    }
    > * {
        position: relative;
    }
}