//
// Base styles
//

.btn {
	display: inline-block;
	font-family: $btn-font-family;
	font-weight: $btn-font-weight;
	line-height: $btn-line-height;
    letter-spacing: 1px;
	color: $body-color;
    text-transform: uppercase;
	text-align: center;
	text-decoration: if($link-decoration == none, null, none);
	white-space: $btn-white-space;
	vertical-align: middle;
	cursor: if($enable-button-pointers, pointer, null);
	user-select: none;
	background-color: transparent;
	border: $btn-border-width solid transparent;
	@include button-size(
		$btn-padding-y,
		$btn-padding-x,
		$btn-font-size,
		$btn-border-radius
	);
	@include transition($btn-transition);

	&:hover {
		color: $body-color;
		text-decoration: if($link-hover-decoration == underline, none, null);
	}

	.btn-check:focus + &,
	&:focus {
		outline: 0;
		box-shadow: $btn-focus-box-shadow;
	}

	.btn-check:checked + &,
	.btn-check:active + &,
	&:active,
	&.active {
		@include box-shadow($btn-active-box-shadow);

		&:focus {
			@include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
		}
	}

	&:disabled,
	&.disabled,
	fieldset:disabled & {
		pointer-events: none;
		opacity: $btn-disabled-opacity;
		@include box-shadow(none);
	}
}

//
// Alternate buttons
//

// scss-docs-start btn-variant-loops
//   @each $color, $value in $theme-colors {
//     .btn-#{$color} {
//       @include button-variant($value, $value);
//     }
//   }

//   @each $color, $value in $theme-colors {
//     .btn-outline-#{$color} {
//       @include button-outline-variant($value);
//     }
//   }

/// @group Custom Buttons
$button-variants: 
    ('primary', $primary, $primary, $white, $forest, $navy),
    ('secondary', $white, $white, $navy, $gray-light, $primary),
    ('light', $white, $white, $navy, $gray-light, $navy),
	('dark', $slate, $slate, $white, $primary, $gray-light);
@each $variant, $bg, $border, $text, $hover, $shadow in $button-variants {
    .btn-#{$variant} {
        @include button-variant($bg, $border, $text, $hover);
        box-shadow: 4px 4px $shadow;
		text-decoration: none;
        &:hover,
        &:focus,
        &:active {
            border-color: $hover;
        }
    }
}

// scss-docs-end btn-variant-loops

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
	font-size: $font-size-base;
	font-weight: bold; // $font-weight-normal;
	color: $navy; // $btn-link-color;
	text-decoration: none; // $link-decoration;
	
	border: none;
	
	padding: .35rem 0;

	position: relative;

	transition: all .1s;

	&:before {
		content: '';
		position: absolute;
		display: block;
		top: 100%;
		left: 0;
		height: 2px;
		background-color: $turqois;
		width: 0;
		transition: width .2s ease-in-out;
	}

	@include arrow-after(1em, $turqois);

	&:hover,
	&:focus {
		color: $turqois;
		text-decoration: none;
		&:before {
			width: 100%;
		}
		// @include arrow-after(1em, $turqois);
	}

	&:active {
		color: $forest;
	}

	&:disabled,
	&.disabled {
		color: $btn-link-disabled-color;
	}

	// No need for an active state here
}

//
// Button Sizes
//

.btn-lg {
	@include button-size(
		$btn-padding-y-lg,
		$btn-padding-x-lg,
		$btn-font-size-lg,
		$btn-border-radius-lg
	);
}

.btn-sm {
	@include button-size(
		$btn-padding-y-sm,
		$btn-padding-x-sm,
		$btn-font-size-sm,
		$btn-border-radius-sm
	);
}
