/// @group SVGHelpers
@mixin arrow-after($size: 1em, $fill: currentColor) {
    $arrow-svg: '<svg xmlns="http://www.w3.org/2000/svg" width="#{$size}" height="#{$size}" fill="#{$fill}" stroke="#{$fill}" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/></svg>';
    &:after {
        content: '';

        background-image: escape-svg(svg-url($arrow-svg));
        background-repeat: no-repeat;
        background-position: center center;
        background-size: $size;

        width: $size;
        height: $size;
        display: inline-block;
        margin-left: .5 * $size;


        body[dir=rtl] & {
            margin-left: initial;
            margin-right: .5 * $size;

            transform: rotate(180deg);
        }
    }
}

// Use an svg for a background image
// @param svg-string the SVG HTML
@mixin svg-background-image($svg-string) {
    $escaped-url: escape-svg( svg-url($svg-string) );
    background-image: $escaped-url;
}