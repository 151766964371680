// @.navbar - ref [v4-beta]
nav.navbar {
	// @Scroll Positioning
	// Navbar is relatively positioned when scrolling down and
	// sticky when scrolling up
	z-index: 1020;
	position: sticky;
	top: 0;
	.admin-bar & {
		top: 32px;
	}
	transition: all .2s;
	[data-scroll-navbar-visible="true"] & {
		transition: all 0;
	}
	[data-scroll-navbar-visible="false"][data-scroll-direction="down"] &[data-drawer-expanded="false"] {
		transform: translateY(-100%);
	}
	&[data-drawer-expanded="true"] {
		transform: none !important;
	}

	// @mobile organization
	@include media-breakpoint-down(lg) {
		.container.flex-wrap-0 {
			flex-wrap: wrap !important;
		}
		.navbar-brand {
			order: 0;
		}
		.navbar-toggler {
			order: 1;
		}
		.navbar-collapse--search {
			order: 2;
		}
	}

	// @Style
	.navbar-light {}
	.navbar-dark {}
	
	// @Home link
	.navbar-brand { }

	%navbar-btn-shadow {
		box-shadow: $box-shadow;
	}

	// @Collapse Toggles
	.navbar-toggler {
		@extend .btn;
		@extend %navbar-btn-shadow;
		border-color: $white;
		background-color: $white;
		color: $blue-ocean;
		padding-left: .5rem;
		padding-right: .5rem;

		svg {
			transition: transform .3;;
		}
		svg rect {
			transition: transform .3s;
			transform-origin: center;
		}

		&[aria-expanded="true"] {
			background-color: $primary;
			border-color: $primary;
			color: $white;
			fill: $white;

			svg {
				transform: translateY(-2px);
			}
			svg rect:first-child {
				transform: rotate(45deg) translateY(9px);
			}
			svg rect:nth-child(2) {
				opacity: 0;
			}
			svg rect:last-child {
				transform: rotate(-45deg) translateY(-6px);
			}
		}
	}

	.navbar-collapse--search {
		.navbar-form {
			flex: 0 0 rem-val(500);
			width: rem-val(500);

			background-color: $gray-light;
			.form-control {
				background-color: transparent;
				border: none;
				border-radius: 0;

				height: rem-val(54);

				@include placeholder {
					color: $primary;
					text-transform: uppercase;
				}
			}
			.btn.btn-primary {
				border-radius: 0;
				box-shadow: none;
				font-size: 1.5rem;
				color: $white;
				fill: $white;
			}
		}
		.nav--social {
			display: flex;
			flex-direction: row;
			flex-grow: 1;
			flex-wrap: nowrap;
			margin: 0 2.5rem;

			.nav-link {
				color: $primary;
				fill: $primary;
				padding-left: .75rem;
				padding-right: .75rem;
				&:hover {
					color: $navy;
					fill: $navy;
				}
			}
		}

		@include media-breakpoint-down(lg) {
			flex: 0 0 100%;
			width: 100%;
			flex-basis: 100% !important;
			flex-wrap: wrap;
			margin-top: .5rem;

			.navbar-form {
				flex: 0 0 100%;
				width: 100%;
			}

			.nav--social {
				margin: 0;
			}
		}
	}

	&[data-drawer-expanded="false"] {
		.navbar-collapse--search {
			display: none !important;
		}
	}
	&[data-drawer-expanded="true"] {
		background-color: $white !important;
		box-shadow: $box-shadow;
		padding-bottom: 0;
		.navbar-collapse--static {
			display: none !important;
			&.navbar-collapse--search {
				display: flex !important;
			}
		}
		.navbar-toggler {
			// margin-left: auto;
		}
	}

	// @Target static and transitional classes
	.collapse,
	.collapsing {
		&.navbar-collapse--static {
			flex-basis: 0;
			font-size: rem-val(14);
			.navbar-nav > .nav-item {
				margin-right: 1.5rem;
			}

			@include media-breakpoint-down(xl) {
				flex-grow: 1 !important;
				.navbar-nav {
					flex-grow: 1 !important;
					justify-content: space-between;
					margin-left: 2rem;
					margin-right: 1.5rem;
				}
				.navbar-nav > .nav-item {
					margin-right: 0;
				}
			}
		}

		&.navbar-collapse--flyout {
			.flyout-columns {
				padding-top: 3rem;
				padding-bottom: 3rem;
			}
			.flyout-column > .nav {
				> .nav-item {
					margin-bottom: 1.5rem;
					.menu-item-has-children {
						& + .nav-item {
							> .nav-link {
								font-weight: bold;
							}
						}
						> .nav-link {
							font-weight: bold;
						}
						.nav {
							padding-left: 1rem;
						}
					}
					> .nav-link {
						font-size: 1.5rem;
						line-height: 1.2;
						font-weight: bold;
						margin-bottom: .75rem;
						&:hover {
							color: $primary;
							text-decoration: none;
						}
					}
				}
			}
			.nav {
				.nav-item {
					.nav-link {
						padding: 0;
						color: $navy;
						margin-bottom: .4rem;
						&:hover {
							color: $navy;
							text-decoration: underline;
						}
					}
				}
			}

			@include media-breakpoint-down(lg) {
				max-height: calc(100vh - 189px - .5rem);
				overflow: scroll;

				.flyout-columns {
					padding-top: 1.5rem;
				}
			}
		}
	
		// @nav ul
		.navbar-nav {
			align-items: center;

			%nav-item-parent {
				text-transform: uppercase;
				font-weight: bold;
			}

			> .nav-item > .nav-link {
				@extend %nav-item-parent;
			}
	
			// @nav li
			.nav-item {
				&.menu-item-has-children {
					> .nav-link {
						@extend %nav-item-parent;
					}
				}

				&.navbar-btn {
					.nav-link {
						@extend .btn;
						@extend .btn-primary;
						@extend %navbar-btn-shadow;
					}
				}
	
				// @nav a
				.nav-link { }
				
				// @nav li.active
				&.active {
					.nav-link {	}
				}
			}
		}
	}

	// @Collapse Content
	.navbar-collapse-content {
		padding-top: 2rem;
		padding-bottom: 2rem;

		background-color: $gray-light;
		font-weight: bold;
		letter-spacing: 1px;
	}

	// @Dropdown Menus
	.dropdown {

		&-menu {
			border: none;
			box-shadow: $box-shadow;
		}

		&-item {
			color: $body-color;
			font-size: rem-val(14);
			text-align: center;
			&:hover {
				color: $primary;
				background-color: initial;
			}
		}

		&-toggle {
			&:after {
				border: none !important;
				content: "^";
				margin-left: 0;
				font-size: 20px;
				line-height: 0;
				vertical-align: 2px;
				transform: rotate(180deg);
			}
			&.show {
				&:after {
					transform: rotate(0deg);
					vertical-align: -6px;
				}
			}
		}
	}
}