/// @group Index
.posts-loop {
    flex-wrap: wrap !important;
}
.post-tease {
    @include media-breakpoint-up(md) {
        flex-basis: calc(#{percentage(1/3)} - ((4/3) * 1rem)) !important;
        flex-grow: 0 !important;
        flex-shrink: 0 !important;
    }

    &-image {
        width: 100%;
        padding-top: percentage(260/380);
        position: relative;
        overflow: hidden;

        border-radius: $border-radius;
        box-shadow: $box-shadow;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            min-width: 100%;
            min-height: 100%;
            transform: translate(-50%,-50%);
            object-fit: cover;
            object-position: center center;
        }
    }
}