/// @group Gutenslider
.wp-block-eedee-block-gutenslider {
    &.is-style-hero {
        --gutenslider-min-height: 655px !important;
        .wp-block-eedee-block-gutenslide {
            &:nth-child(3n+2) {
                .bg-image:before {
                    background-color: $turqois;
                }
            }
            &:nth-child(3n+3) {
                .bg-image:before {
                    background-color: $green;
                }
            }
            .bg-image, .eedee-background-div {
                &:before {
                    opacity: 0;
                    transition: opacity .6s ease-in-out;
                    position: absolute;
                    width: 90%;
                    padding-top: 90%;
                    background-color: $turqois-ada;
                    border-radius: 50%;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    animation: bgImage .2s ease-in-out;
                }
                @keyframes imgAnim {
                    from {
                        opacity: 0;
                        transform: translateY(100%);
                    }
                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
                img {
                    position: relative;
                    opacity: 0;
                    transition: all 1s ease-in-out;
                    transform: translateY(100%);
                }
            }
            &.swiper-slide-active {
                .bg-image, .eedee-background-div {
                    &:before {
                        opacity: 1;
                    }
                    img {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }
        }
        .swiper-initialized {
            .wp-block-eedee-block-gutenslide {
                .b-image, .eedee-background-div {
                    &:before {
                        content: '';
                    }
                }
            }
        }
    }

    /// @group Testimonials
    &.is-style-testimonial-slider {
        .swiper {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
        .slide-content {
            padding: 0 !important;
            width: 100% !important;
            > * {
                width: 100% !important;
            }
            .is-style-testimonial {
                @include media-breakpoint-up(lg) {
                    padding: 3rem 5rem !important;
                }
            }
        }
        .swiper-pagination-bullets {
            bottom: 2rem !important;
            .swiper-pagination-bullet {
                width: 12px;
                height: 12px;
                background-color: $gray-medium;
                &-active {
                    background-color: $turqois;
                }
            }
        }
    }
}