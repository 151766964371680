/// @group Sizes
$gutenberg-custom-fonts:
	( 'default', 18, normal, 1.55 ),
	( 'small', 14, normal, 1.55 ),
	( 'medium', 24, normal, 1.35 ),
	( 'intro', 32, normal, 1.25 ),
	( 'preheading', 28, 700, 1.175 ),
	( 'heading-1', 60, 500, 1.175 ),
	( 'heading-2', 50, 500, 1.175 ),
	( 'heading-3', 36, 500, 1.175 ),
	( 'heading-4', 32, normal, 1.175 ),
	( 'heading-5', 30, 500, 1.175 ),
	( 'heading-6', 18, 700, 1.175 );
@each $slug, $size, $weight, $line-height in $gutenberg-custom-fonts {
	.has-#{$slug}-font-size {
		font-size: #{$size}px;
		font-weight: $weight;
		line-height: $line-height;
	}
}