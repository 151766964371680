/// @group Donate-Form
#bbox-root {
    margin: 3rem 0;

    * {
        font-size: 1rem !important;
    }

    form {
        .BBFormContainer {
            font-size: 1rem;
        }

        background-color: $gray-light;
        padding: 1.5rem 1rem;

        legend {
            .BBFormSectionHeading {
                padding: 0;
                margin: 0 0 1.5rem;
                border-bottom: none;

                label {
                    font-size: 24px !important;
                    line-height: 26px;
                    font-weight: bold;
                }
            }
        }

        .BBFormSection {
            width: 100%;
            max-width: 100%;
            margin: 0 0 2rem;
        }

        .BBFormFieldContainerGivingLevels {
            padding: 0;
            margin: 0;
            font-size: 1rem;

            .BBFormRadioList {
                width: 100%;
                input + label,
                input + label input {
                    border-color: $gray-medium;
                    border-width: 1px;
                    background-color: $white;
                    color: $dark;
                    font-size: 1rem;
                    line-height: 1.6;
                    padding: .5rem 2rem;
                    font-weight: bold;
                }
                input:checked + label,
                input + label:hover {
                    background-color: $turqois;
                }

                .BBFormRadioLabelGivingLevelOther {
                    &.BBFormRadioLabelGivingLevelSelected {
                        padding: 0;
                        border: none;
                        background-color: transparent;
                        input {
                            width: 100%;
                        }
                    }
                }
            }
        }

        // .BBFormFieldContainer {
        //     margin: 0;
        //     width: 100%;
        //     label {
        //         color: $body-color;
        //         display: block;
        //         float: none;
        //         text-align: left;
        //         font-weight: normal !important;
        //         font-size: 1rem;
        //     }
        //     input[type="text"],
        //     input[type="email"],
        //     textarea {
        //         @extend .form-control;
        //         border-radius: 5px;
        //         min-height: 44px;
        //         border-color: $gray-medium;
        //     }
        //     select {
        //         @extend .form-select;
        //         border-radius: 5px;
        //         min-height: 44px;
        //         border-color: $gray-medium;
        //         color: $dark;
        //     }
        //     input[type="checkbox"],
        //     input[type="radio"] {
        //         @extend .form-check;
        //         display: inline-block;
        //         margin-right: 1rem;
        //         & + label {
        //             display: inline-block;
        //             width: auto;
        //         }
        //     }
        // }

        input[type="submit"] {
            @extend .btn;
            @extend .btn-primary;
        }
    }
}