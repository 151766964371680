//@description Remove units for a string
//@author Lew Krehnbrink
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

//@description Convert unit(less) number to rem
//@author Lew Krehnbrink
@function rem-val($number, $base: 16) {
    $number: strip-unit($number);
    $val: $number / $base;
    $return: $val * 1rem;
    @return $return;
}

//@description Reverse ordering of SASS Map
//@author Michael Whelan
@function mapReverse ($map) {
    $result: null;
    @if type-of($map) == "map" {
        $keys: map-keys($map);
        $map-reversed: ();
        @for $i from length($keys) through 1 {
            $map-reversed: map-merge(
                $map-reversed,
                (nth($keys, $i): map-get($map, nth($keys, $i)))
            );
        }
        @if type-of($map-reversed) == "map" {
            $result: $map-reversed;
        } @else {
            @warn 'There was an error reversing the order of "#{$map}"';
        }
    } @else {
        @warn '"#{$map}" is not a valid map';
    }
    @return $result;
}

//@description get url path to assets directory
//@author LWK
@function asset($filename) {
    @return $asset-dir + $filename;
}

//@descrption convenience mechanism for inline svg
@function svg-url($svg-string) {
    @return url("data:image/svg+xml,#{$svg-string}");
}