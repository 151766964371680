// @gravity forms
.gform_wrapper {
	/// @group Footer
	.gform-inline & form {
		// display: flex;
		// align-items: center;

		background-color: transparent; // $white;
		border-radius: $border-radius;
		// overflow: hidden;

		.gform_body {
			flex-grow: 1;
		}

		.gform_body,
		.gform_footer {
			padding: 0 !important;
			margin: 0 !important;

			align-self: stretch;

			.gform_fields {
				.gfield {
					margin-bottom: 0 !important;

					.ginput_container {
						input {
							border: none !important;
							padding-left: 1rem !important;
							padding-right: 1rem !important;
							color: $navy;
							@include placeholder {
								color: $navy;
							}
						}
						select {
							border: 1px solid #fff;
						}
					}
				}
			}

			.btn {
				height: 100%;
				min-height: 52.55px;
				box-shadow: none;
				margin-bottom: 0;
				margin-top: 1rem;
				background-color: $turqois;
				border-color: $turqois;
			}
		}
	}

	// Grid - for use with updated layout editor
	&.gravity-theme {
		* { 
			box-sizing: border-box;
		}

		.gform_body {
			.gform_fields {
				width: 100%;
				display: grid;
				grid-template-columns: repeat(12, 1fr);
				grid-template-rows: repeat(auto-fill, auto);
				grid-column-gap: 12px;
				grid-row-gap: 12px;

				.gfield {
					// grid-column: 1 / -1;
					margin-bottom: 0 !important;

					.ginput_container {
						&:not(.ginput_container_radio):not(.ginput_container_checkbox) {
							input,
							select {
								min-height: calc(1em + 1.61111rem + 2px);
								padding: 0.80556rem 1.55556rem !important;
								font-size: 1rem !important;
								border-radius: 0.27778rem !important;

								&.large {
									font-size: 1rem !important;
								}
							}
						}
					}

					&--width-half {
						> div {
							input,
							select {
								min-height: calc(1em + 1.61111rem + 2px);
								padding: 0.80556rem 1.55556rem !important;
								font-size: 1rem !important;
								border-radius: 0.27778rem !important;
							}
						}
					}
				}
			}
		}

		.gform_footer {
			padding: 0 !important;
		}

		$grid-sizes: (
			'full': 12,
			'eleven-twelfths': 11,
			'five-sixths': 10,
			'three-quarter': 9,
			'two-thirds': 8,
			'seven-twelfths': 7,
			'half': 6,
			'five-twelfths': 5,
			'third': 4,
			'quarter': 3,
		);

		@each $key, $size in $grid-sizes {
			.gfield.gfield--width-#{$key} {
				-ms-grid-column-span: $size;
				grid-column: span $size;
			}
		}

		@media (max-width: 640px) {
			.gform_fields {
				grid-column-gap: 0;
			}

			.gfield:not(.gfield--width-full) {
				grid-column: 1 / -1;
			}
		}
	}

	// Heading
	.gform_heading {
		.gform_title {
		}

		.gform_description {
		}
	}

	// @local

	.gform_body {
		.gform_fields {
			// radio and checkbox
			fieldset.gfield {
				@extend .form-text;
				color: inherit;
			}

			.gfield {
				@extend .input-group;
				@extend .mb-3;
				flex-direction: column;

				&_contains_required {
					&:focus-within {
						.gfield_required {
							// opacity: 1 !important;
						}
					}
				}

				// @label
				.gfield_label {
					@extend .form-label;

					// @required
					.gfield_required {
						@extend .form-text;
						color: $danger;
						margin-top: 0;
						margin-left: 0.25em;
						// &:before {
						// 	content: '*';
						// }

						// &_text {
						// 	opacity: 0;
						// }
					}
				}

				&.label_hidden {
					.gfield_label {
					}
				}

				// @section
				&.gsection {
					.gsection_title {
					}
				}

				// @input container
				.ginput_container {
					// @general input
					input[type='text'],
					input[type='email'],
					input[type='number'],
					input[type='tel'],
					input[type='url'],
					textarea {
						@extend .form-control;

						&.large {
							@extend .form-control-lg;
						}

						&.small {
							@extend .form-control-sm;
						}

						&:focus,
						&:focus-visible {
						}
					}

					// @select
					select {
						@extend .form-select;
						min-height: calc(1em + 1.61111rem + 2px);
						padding: 0.80556rem 1.55556rem !important;
						font-size: 1rem !important;
						border-radius: 0.27778rem !important;
					}

					// @multiple - select
					[multiple='multiple'] {
						option {
						}
					}

					// @type="file"
					[type='file'] {
						@extend .form-control;
					}

					// @radio
					&.ginput_container_radio {
						ul.gfield_radio li {
							@extend .form-check;
						}

						// radio base styles
						input[type='radio'] {
							@extend .form-check-input;
						}

						// styling for custom radio unchecked
						input[type='radio'] + label {
							@extend .form-check-label;

							&:before {
							}

							@include on-action() {
								&:before {
								}
							}
						}

						// styling for custom radio checked
						input[type='radio']:checked + label {
							&:before {
							}

							&.accent_blue {
								&:before {
								}
							}

							&.accent_green {
								&:before {
								}
							}
						}
					}

					// @radio_end
					// @checkbox
					&.ginput_container_checkbox {
						ul.gfield_checkbox li {
							@extend .form-check;
						}

						//checkbox with before text
						input[type='checkbox'] {
							@extend .form-check-input;
						}

						// styling for custom checkbox unchecked
						input[type='checkbox'] + label {
							@extend .form-check-label;

							&:before {
							}

							@include on-action() {
								&:before {
								}
							}
						}

						// styling for custom checkbox checked
						input[type='checkbox']:checked + label {
							&:before {
							}

							&.accent_blue {
								&:before {
								}
							}

							&.accent_green {
								&:before {
								}
							}
						}
					}

					&.ginput_container_consent {
						@extend .form-check;

						//checkbox with before text
						input[type='checkbox'] {
							@extend .form-check-input;
							min-height: 0 !important;
							&:first-of-type {
								padding: 0 !important;
							}
						}

						// styling for custom checkbox unchecked
						input[type='checkbox'] + label {
							@extend .form-check-label;
						}

						label, 
						.gfield_required {
							display: inline;
						}

						label {
							margin-right: .5rem;
						}

						.gfield_required {
							color: inherit;
						}
					}

					// @checkbox_end

					// @complex input - 'custom input type' styling
					&.ginput_complex {
						> span {
							input {
								min-height: calc(1em + 1.61111rem + 2px);
								padding: 0.80556rem 1.55556rem;
								font-size: 1rem !important;
								border-radius: 0.27778rem;
							}

							&.address_line_1 {
							}

							&.address_line_2 {
							}

							label {
								margin-bottom: 0.5rem;
							}
						}
					}
				}

				&.hidden_label {
					.ginput_container_consent .gfield_required {
						display: none;
					}
				}
			}
		}
	}

	//@gform_body
	.gform_footer,
	.gform_page_footer {
		@extend .mt-4;

		input[type='submit'],
		[type='button'],
		.btn--gform {
			@extend .btn;
			@extend .btn-primary;
		}
	}

	// @gform_footer

	// @errors / validation
	&.gform_validation_error {
		// @extend .was-validated;

		.gform_submission_error {
			// @extend .invalid-feedback;
			@extend .lead;
			@extend .mb-3;
			color: $danger;
			line-height: $line-height-base;
		}

		form {
			.gform_body {
				.gform_fields {
					.gfield_error {
						input {
							// @extend .is-invalid;
						}

						.validation_message {
							@extend .invalid-feedback;
							display: block;
							font-size: 1rem * (16 / 20);
						}
					}
				}
			}
		}
	}

	// @gform_validation_error

	.validation_error {
	}

	// @validation_error
}

// Confirmation
.gform_confirmation {
	&_wrapper {
	}

	&_message {
	}
}

/// google recaptcha logo
.grecaptcha-badge {
	// hide

	@include on-action() {
	}
}

.gform_wrapper .gform_body .gform_fields .gfield.gform_hidden {
	margin-bottom: 0 !important;
	position: absolute;
}

/// @group Quiz
.gform_wrapper {
	.gform_validation_errors {
		margin-bottom: 1.5rem !important;
		h2.gform_submission_error {
			margin: 1rem 0 !important;
			font-weight: 500;
		}
	}
	.gform_body {
		.gform_fields {
			fieldset.gquiz-field {
				&:first-child {
					margin-top: 2rem;
				}

				flex-direction: column;
				flex-wrap: nowrap;

				legend {
					color: $body-color;
					.gfield_required {
						display: none;
					}
				}

				.ginput_container {
					&.ginput_container_radio {
						.gchoice {
							margin-bottom: 0.5rem;
						}
					}
				}

				&.is-valid,
				&.is-invalid {
					.ginput_container {
						&.ginput_container_radio {
							.gchoice {
								input {
									padding: 0 !important;
									border-color: initial !important;
									background-image: none !important;
								}
							}
						}
					}
				}
			}
		}
	}

	.was-validated .gform_body {
		.gform_fields {
			fieldset.gquiz-field {
				legend {

				}

				.ginput_container {
					&.ginput_container_radio {
						.gchoice {
							input {
								padding: 0 !important;
								border: 1px solid rgba(0, 0, 0, 0.25) !important;
								background-image: none !important;
								&:checked {
									background-image: escape-svg($form-check-radio-checked-bg-image) !important;
								}
								&.is-valid + label {
									color: #6c757d;
								}
							}
						}
					}
				}

				&.is-invalid {
					label {
						color: $danger !important;
					}
				}
			}
		}
	}
}

/// @group GQuizResults
.gquiz {
	&_confirmation_message {}

	&-container {}

	&-field-label {
		margin-bottom: 1rem;
	}

	&-field-choice,
	&-answer-explanation {
		padding-left: 1rem;
	}

	&-answer-explanation {
		font-style: italic;
		@extend .bg-light;
		padding: 1rem;
		border-radius: $border-radius;
		&:before {
			font-weight: 500;
			text-decoration: underline;
			margin-right: .5rem;
			content: 'Explanation:';
		}
	}

	&-field {
		ul {
			li {
				padding-left: 0 !important;
				&:before {
					display: none;
				}

				img {
					width: 18px;
					height: 18px;
				}

				&.gquiz-correct-choice {
					color: $success;
				}
				&.gquiz-incorrect-response {
					color: $danger;
				}
				&.gquiz-correct-response {
					// &:after { 
					// 	content: 'Right!';
					// }
				}
			}
		}
	}
}
