/// @group IframeToggle
.editor-styles-wrapper a[href^="iframe:"],
#entry-content a[data-toggle="iframe-modal"],
.wp-block-media-text.has-media-link .wp-block-media-text__media a {
    position: relative;
    &:after {
        content: '';
        position: absolute;
        display: block;
        left: calc(50% - #{50px / 2});
        top: calc(50% - #{60px / 2});
        border-left: 50px solid #fff;
        border-top: 30px solid transparent;
        border-bottom: 30px solid transparent;
        border-right: none;

        transition: border-color .2s;
    }
    &:hover {
        &:after {
            border-left-color: $turqois !important;
        }
    }
    img {
        position: relative;
    }
}