/// @group Tease
.tease {
    padding-left: 12px;
    padding-right: 12px;
    .row {
        height: 100%;
    }
    &-media {
        border-radius: $border-radius;
        overflow: hidden;
        .tease-image {
            height: 100%;
            width: 100%;
            img {
                position: relative;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
    }
    &-image {
        margin-bottom: 0;
    }
    &--honoree {
        .tease-content {
            padding-right: 1.25rem !important;
            padding-left: 1.25rem !important;

            h3 {
                font-size: 1rem;
                margin-bottom: .5rem !important;
            }
        }
    }
    &--event {
        box-shadow: $box-shadow;
        background-color: $white;
        border-radius: $border-radius;

        align-self: stretch;
        width: 100%;
        &:not(.tease--vertical) {
            .tease-image {
                height: 100%;
                position: relative;
                img {
                    display: block;
                    @include media-breakpoint-up(lg) {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                    }
                }
            }
        }
        &.tease--vertical {
            > .row {
                display: block;
            }
            .tease-media {
                height: auto;
                .tease-image,
                img {
                    height: auto;
                }
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
            }
        }
    }
}