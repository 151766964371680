/// @group Grid
/// helpers for grid alignment in odd situations
.wp-block-media-text:not(.alignwide):not(.alignfull) {
    // fit content block to container sizing with padding
    @each $bp, $px in $container-max-widths {
        @include media-breakpoint-up($bp) {
            .wp-block-media-text__content {
                padding-right: calc( ( 100vw - #{$px} ) / 2 + ( #{$grid-gutter-width} * .5 ) );
                padding-left:  $grid-gutter-width * .5;
            }
            &.has-media-on-the-right {
                .wp-block-media-text__content {
                    padding-right: $grid-gutter-width * .5;
                    padding-left: calc( ( 100vw - #{$px} ) / 2 + ( #{$grid-gutter-width} * .5 ) );
                }
            }
        }
    }
}