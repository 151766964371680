/// @group Grid
.row {
    &--overflow-scroll {
        @include media-breakpoint-up(lg) {
            width: 100%;
            overflow: scroll;
            display: flex;
            flex-wrap: nowrap;
        }
    }
}