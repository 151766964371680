/// On-action Mixin
/// @description Combine hover, active and focus states into one css declaration
/// @group _mixins
/// @author Lew Krehnbrink
@mixin on-action() {
	&:hover,
	&:active,
	&:focus {
		outline: none;
		@content;
	}
}