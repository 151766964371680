$asset-dir: '/wp-content/themes/usdigital-child/assets/dist/' !default;

/// @group Fonts
// import your fonts here

/// @group Support
@import 
    'functions';

/// @group Bootstrap
// Bootstrap Source
// We'll pick and choose the BS modules we want 
// to use for this project.
// scss-docs-start import-stack
@import 
    '~bootstrap/scss/functions', 
    '~bootstrap/scss/variables',
    'bootstrap.scss',
    '~bootstrap/scss/mixins', 
    '~bootstrap/scss/utilities', 
    '~bootstrap/scss/root', 
    '~bootstrap/scss/reboot', 
    '~bootstrap/scss/type', 
    '~bootstrap/scss/images', 
    '~bootstrap/scss/containers', 
    '~bootstrap/scss/grid', 
    '~bootstrap/scss/tables', 
    '~bootstrap/scss/forms', 
    // '~bootstrap/scss/buttons', 
    '~bootstrap/scss/transitions', 
    '~bootstrap/scss/dropdown', 
    '~bootstrap/scss/button-group', 
    '~bootstrap/scss/nav', 
    '~bootstrap/scss/navbar', 
    '~bootstrap/scss/card', 
    '~bootstrap/scss/accordion', 
    '~bootstrap/scss/breadcrumb', 
    '~bootstrap/scss/pagination', 
    '~bootstrap/scss/badge', 
    '~bootstrap/scss/alert',
    '~bootstrap/scss/progress', 
    '~bootstrap/scss/list-group', 
    '~bootstrap/scss/close', 
    '~bootstrap/scss/toasts', 
    '~bootstrap/scss/modal', 
    '~bootstrap/scss/tooltip', 
    '~bootstrap/scss/popover', 
    '~bootstrap/scss/carousel', 
    '~bootstrap/scss/spinners', 
    '~bootstrap/scss/offcanvas', 
    '~bootstrap/scss/helpers', 
    '~bootstrap/scss/utilities/api';
// scss-docs-end import-stack

/// @group Accessibility
@import 
    'accessibility';

/// @group Mixins 
// Support Functionality (e.g. media queries, button variants, etc.)
@import
  'mixins/actions',
//   'mixins/common',
  'mixins/container',
  'mixins/font',
  'mixins/form',
  'mixins/gradient',
  'mixins/position',
  'mixins/scrollbar',
  'mixins/svg';

/// @group Overrides
@import
    'components/breadcrumb',
    'components/button';

/// @group Blocks
// Handle client import in blocks directory
$frontend: true;
@import 
    '../../../blocks/src/styles/client.scss';
// @import 
//     'blocks/core',
//     'blocks/colors',
//     '../../../blocks/src/styles/button',
//     '../../../blocks/src/styles/gutenslider',
//     '../../../blocks/src/styles/list',
//     '../../../blocks/src/styles/sizes';

/// @group WooCommerce
// @devnote move to own entry
// @import
//     'woo/woocommerce.scss';


/// @group Vendor - 3rd Party Customizations 
// Framework / Plugin Specific styling (e.g. Wordpress, Gravity Forms, WooCommerce, etc.)
@import 
    // 'vendor/wp',
    'vendor/gf';

/// @group Glide
// @import 
//     '~@glidejs/glide/src/assets/sass/glide.core.scss';


/// @group Components 
// Collective BS components (e.g. .carousel, .navbar, .modal)
@import
    'components/typography',
    'components/button',
    'components/donate-form',
    'components/footer',
    'components/gdpr',
    'components/grid',
    'components/header',
    'components/list',
    'components/tease';

/// @group Views
@import 
    'views/index',
    'views/single-post';

/// @group Entry
#entry-content {
    font-size: 18px;
}

// Browser overrides
:focus-visible {
    outline-color: $primary;
}