/// @group Blockquote
blockquote {
    &.is-style-huge-quote,
    &.is-style-huge-quote-callout {
        color: $navy;
        padding: 4rem 3rem !important;
        p {
            font-size: 48px;
            font-weight: bold;
            line-height: 1.2;
        }
    }
    &.is-style-huge-quote-callout {
        min-height: 450px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-image: url(#{asset('images/callout-bg-2.png')});
        background-size: cover;
        background-repeat: no-repeat;
    }
}