/// @group Button
.wp-block-button {
    &__link {
        @extend .btn;
    }
    @each $variant, $bg, $border, $text, $hover, $shadow in $button-variants {
        &.is-style-#{$variant} {
            .wp-block-button__link {
                @extend .btn-#{$variant};
            }
        }
    }
    &.is-style-link {
        .wp-block-button__link {
            @extend .btn-link;
            text-align: left;
        }
    }
}