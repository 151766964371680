/// @group Colors
$gutenberg-colors:
    ( 'Lime', 'lime', #ACDD71 ),
    ( 'Mint', 'mint', #89D283 ),
    ( 'Green', 'green', #199956 ),
    ( 'Turqois', 'turqois', #57C39C ),
    ( 'Turqois ADA', 'turqois-ada', #3BA57E ),
    ( 'Forest', 'forest', #2E7F63 ),
    ( 'Navy', 'navy', #313E50 ),
    ( 'Slate', 'slate', #293443 ),
    ( 'Blue', 'blue', #39548B ),
    ( 'Blue Ocean', 'blue-ocean', #1984be ),
    ( 'Blue Medium', 'blue-medium', #8296C9 ),
    ( 'Blue Light', 'blue-light', #CAD2EA ),
    ( 'Gold', 'gold', #F5BB2B ),
    ( 'Yellow', 'yellow', #FAD748 ),
    ( 'Yellow Light', 'yellow-light', #F9DD89 ),
    ( 'Salmon', 'salmon', #F36F56 ),
    ( 'Rose', 'rose', #FF9085 ),
    ( 'Melon', 'melon', #F8924F ),
    ( 'Gray Light', 'gray-light', #f7f7f7 ),
    ( 'Gray Medium', 'gray-medium', #8B929B ),
    ( 'White', 'white', #ffffff );
@each $title, $slug, $hex in $gutenberg-colors {
    .has-#{$slug}-color {
        color: $hex !important;
        a {
            color: $hex;
        }
    }
    .has-#{$slug}-background-color {
        background-color: $hex !important;
    }
}