/// @group List
ul,
ol {
    &.nav {
        &.nav--social-share {
            .nav-item + .nav-item {
                margin-top: 1rem;
            }
            span.nav-link {
                color: $navy;
            }
            a.nav-link {
                svg {
                    fill: $gray-medium;
                }
                &:hover {
                    svg {
                        fill: $turqois-ada;
                    }
                }
            }
        }
    }
    &.pagination {
        li.page-item {
            padding-left: 0 !important;
            &:before {
                display: none;
            }
        }
    }
}