/// @group SinglePost
.post-image {
    max-height: 500px;
    overflow: hidden;
    box-shadow: $box-shadow;
    img {
        object-fit: cover;
        width: 100%;
        height: auto;
    }
}
.post-header {
    a {
        text-decoration: none;
        color: $turqois;
    }
    h1, p {
        margin-bottom: 1rem !important;
    }
}