/// @group List
.editor-styles-wrapper ul.wp-block,
#entry-content ul:not(.nav):not(.list-unstyled) {
    list-style-type: none !important;
    list-style: none !important;
    padding-left: 0;
    li {
        padding-left: 1.5em;
        margin-bottom: .5em;
        position: relative;
        &:before {
            content: '\025c6';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            font-weight: bold;
            font-size: .8em;
            line-height: 1.8;
        }
    }
    &.is-style-grey-bullets {
        li {
            &:before {
                color: $gray;
            }
        }
    }
    &.is-style-green-bullets {
        li {
            &:before {
                color: $turqois;
            }
        }
    }
}