/// @group Blockquote
/// @group Testimonial
/// @group TestimonialSlider
.is-style-testimonial {
    padding: 2rem;
    @include media-breakpoint-up(lg) {
        padding: 4rem;
    }
    border-top: 7px solid $turqois;

    blockquote {
        &.is-style-plain {
            padding-left: 0;
        }
    }
    img {
        box-shadow: $box-shadow;
        margin-right: 1rem;
    }
}

blockquote {
    margin-top: 2rem;
    p {
        font-size: 18px;
        line-height: 1.6;
    }
}

