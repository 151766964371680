/// @group Footer
footer {
	&.page-footer {
        .footer-main {
            color: $white;
            background-color: $navy;

            padding-top: 3rem;
            padding-bottom: 3rem;
        }

        .footer-socket {
            font-size: rem-val(12);

            color: $gray-medium;
            background-color: $slate;

            padding-top: 2rem;
            padding-bottom: 2rem;

            a,
            .icon {
                color: $gray-medium;
                fill: $gray-medium;
                &:hover {
                    color: $primary;
                    fill: $primary;
                }
            }

            p {
                margin-bottom: 0;
            }
        }

        h2,
        .footer-col-title {
            font-size: rem-val(18);
            text-transform: uppercase;
            font-weight: bold;
            color: $turqois;
            margin-bottom: .75rem;
        }

        .gform-inline {
            margin-bottom: 1.5rem;
        }

        .gform_description {
            font-size: rem-val(26);
            font-weight: bold;
            line-height: 1.2;
            display: block;
            margin-bottom: 1rem !important;
        }

        @include media-breakpoint-up(lg) {
            .bordered {
                border-left: 1px solid rgba(#D8D8D8, .5);
                padding-left: 2rem;
                padding-right: 2rem;
            }
        }
    }
}

/// @group Nav
/// footer nav variant
.nav.nav--footer {
    .nav-item {
        .nav-link {
            color: $white;
            padding: 0;
            font-weight: 300;
            @include on-action {
                color: $primary;
            }
        }
        &[class*="column-"] {
            > .nav-link {
                font-weight: bold;
                text-transform: uppercase;
                margin-bottom: .75rem;
            }
            &.menu-item-has-children {
                margin-bottom: 1.5rem;
            }
        }
    }
}