/// @group Mobile
$device-bp: lg;

#entry-content { // for specificity

    /// @group Columns
    .wp-block-columns {
        .wp-block-column {
            &:empty {
                padding: 0 !important;
                margin: 0 !important;
            }

            @include media-breakpoint-down($device-bp) {
                &.post-tease {
                    // padding-left: 0;
                    // padding-right: 0;
                }
            }
        }
    }

    /// @group MediaText
    .wp-block-media-text {
        @include media-breakpoint-down($device-bp) {
            grid-template-columns: 100% !important;
            &__content,
            &__media {
                grid-column: 1 !important;
                margin-left: 0;
                margin-right: 0;
            }
            &__content {
                grid-row: 2 !important;
                padding-bottom: 2rem;
            }
            &__media {
                grid-row: 1 !important;
                margin-bottom: 2rem;
            }
        }
    }
    .posts-header .wp-block-media-text {
        @include media-breakpoint-down($device-bp) {
            &__media {
                margin-bottom: 0;
            }
        }
    }

}